import { ObjectionDecision, TaxtatieType } from '../../../types/apiRequests'

export const compareStatusChoices = [
  { id: '1', name: 'resources.objections.compare.higher' },
  { id: '2', name: 'resources.objections.compare.equal' },
  { id: '3', name: 'resources.objections.compare.lower' },
]

export const objectionDecisionChoices = Object.values(ObjectionDecision).map(decision => ({
  id: decision,
  name: `resources.objections.decisions.${decision}`,
}))

export const taxationTypes = Object.values(TaxtatieType).map(type => ({
  id: type,
  name: `resources.objections.properties.compensation.${type}`,
}))