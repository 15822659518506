/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_create_objection_api_v1_objection_post */
export interface BodyCreateObjectionApiV1ObjectionPost {
  /** Municipalityid */
  municipalityId?: string | null;
  /** Municipalitycode */
  municipalityCode?: string | null;
  /** Objection Number */
  objection_number?: string | null;
  /**
   * File
   * @format binary
   */
  file: File;
}

/** ConfigurationOut */
export interface ConfigurationOut {
  /**
   * Unique identifier
   * @format uuid
   */
  id: string;
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** ConfigurationUpdate */
export interface ConfigurationUpdate {
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** CourtCreate */
export interface CourtCreate {
  /** Court Name */
  name: string;
  /** Team Name */
  teamName: string;
  /** Mailbox */
  mailbox: string;
  /** Postal Code */
  postalCode: string;
  /** City */
  city: string;
}

/** CourtOut */
export interface CourtOut {
  /** Court Name */
  name: string;
  /** Team Name */
  teamName: string;
  /** Mailbox */
  mailbox: string;
  /** Postal Code */
  postalCode: string;
  /** City */
  city: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  id: string;
}

/** CourtUpdate */
export interface CourtUpdate {
  /** Court Name */
  name: string;
  /** Team Name */
  teamName: string;
  /** Mailbox */
  mailbox: string;
  /** Postal Code */
  postalCode: string;
  /** City */
  city: string;
}

/** Grief */
export interface Grief {
  /** Griefid */
  griefId: string;
  /** Citaat */
  citaat: string;
  /** Advice Text */
  advice_text?: number | null;
  /** Decision Text */
  decision_text?: number | null;
  /** Decision Motivation */
  decision_motivation?: string | null;
}

/** Grievance */
export interface Grievance {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: number;
  /** List of decision texts */
  decision_texts: GrievanceText[];
}

/** GrievanceCreate */
export interface GrievanceCreate {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: number;
  /** List of decision texts */
  decision_texts: GrievanceText[];
}

/** GrievanceOut */
export interface GrievanceOut {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: number;
  /** List of decision texts */
  decision_texts: GrievanceText[];
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the grievance
   * @format uuid
   */
  id: string;
}

/** GrievanceText */
export interface GrievanceText {
  /** Decision on the grievance */
  decision: string;
  /** Value influence of the grievance */
  valueInfluence: boolean;
  /** Descriptions of the grievance text */
  description: string;
}

/** GrievanceUpdate */
export interface GrievanceUpdate {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: number;
  /** List of decision texts */
  decision_texts: GrievanceText[];
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Inboekvariabelen */
export interface InboekvariabelenInput {
  /** Dagtekening */
  dagtekening?: string | null;
  /** Waardepeildatum */
  WaardePeildatum?: string | null;
  /** Proforma */
  ProForma: boolean;
  /** Ontvankelijk */
  Ontvankelijk: boolean;
  /** Horen */
  Horen: boolean;
  /** Taxatieverslag */
  Taxatieverslag: boolean;
  /** Taxatiegesprokenmet */
  TaxatieGesprokenMet?: string | null;
  /** Taxatiesoortopname */
  TaxatieSoortOpname?: string | null;
  /** Taxatieobjectomschrijving */
  TaxatieObjectOmschrijving?: string | null;
  /** Onkostenvergoeding */
  Onkostenvergoeding: boolean;
  Vergoeding?: VergoedingOnderdelen | null;
  /** Uitstelbetaling */
  UitstelBetaling: boolean;
  /** Voldoetaanformelevereisten */
  VoldoetAanFormeleVereisten?: boolean | null;
  /** Vergelekenwoningen */
  VergelekenWoningen?: VergelekenWoning[] | null;
}

/** Inboekvariabelen */
export interface InboekvariabelenOutput {
  /** Dagtekening */
  dagtekening?: string | null;
  /** Waardepeildatum */
  WaardePeildatum?: string | null;
  /** Proforma */
  ProForma: boolean;
  /** Ontvankelijk */
  Ontvankelijk: boolean;
  /** Horen */
  Horen: boolean;
  /** Taxatieverslag */
  Taxatieverslag: boolean;
  /** Taxatiegesprokenmet */
  TaxatieGesprokenMet?: string | null;
  /** Taxatiesoortopname */
  TaxatieSoortOpname?: string | null;
  /** Taxatieobjectomschrijving */
  TaxatieObjectOmschrijving?: string | null;
  /** Onkostenvergoeding */
  Onkostenvergoeding: boolean;
  Vergoeding?: VergoedingOnderdelen | null;
  /** Uitstelbetaling */
  UitstelBetaling: boolean;
  /** Voldoetaanformelevereisten */
  VoldoetAanFormeleVereisten?: boolean | null;
  /** Vergelekenwoningen */
  VergelekenWoningen?: VergelekenWoning[] | null;
}

/** MunicipalityCreate */
export interface MunicipalityCreate {
  /** Municipality Code */
  municipalityCode: string;
  /** Municipality Name */
  name: string;
  /** Phone Number */
  phoneNumber: string;
  /** Municipality Grievances */
  municipality_grievances: string[] | null;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  courtId: string;
}

/** MunicipalityGrievanceCreate */
export interface MunicipalityGrievanceCreate {
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
}

/** MunicipalityGrievanceOut */
export interface MunicipalityGrievanceOut {
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the municipality grievance
   * @format uuid
   */
  id: string;
}

/** MunicipalityGrievanceOutWithGrievance */
export interface MunicipalityGrievanceOutWithGrievance {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the municipality grievance
   * @format uuid
   */
  id: string;
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
  /** Grievance details */
  grievance: Grievance;
}

/** MunicipalityGrievanceUpdate */
export interface MunicipalityGrievanceUpdate {
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
}

/** MunicipalityOut */
export interface MunicipalityOut {
  /** Municipality Code */
  municipalityCode: string;
  /** Municipality Name */
  name: string;
  /** Phone Number */
  phoneNumber: string;
  /** Municipality Grievances */
  municipality_grievances: string[] | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the municipality
   * @format uuid
   */
  id: string;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  courtId: string;
}

/** MunicipalityUpdate */
export interface MunicipalityUpdate {
  /** Municipality Code */
  municipalityCode: string;
  /** Municipality Name */
  name: string;
  /** Phone Number */
  phoneNumber: string;
  /** Municipality Grievances */
  municipality_grievances: string[] | null;
}

/** ObjectionDecision */
export enum ObjectionDecision {
  GROUNDED = "GROUNDED",
  UNFOUNDED = "UNFOUNDED",
}

/** ObjectionOut */
export interface ObjectionOut {
  /** Reference number of the object */
  object_reference_number?: string | null;
  /** WOZ object number */
  object_woz_number?: string | null;
  /** City of the object */
  object_city?: string | null;
  /** Street of the object */
  object_street?: string | null;
  /** House number of the object */
  object_house_number?: string | null;
  /** Name of the requestor */
  subject_name?: string | null;
  /** City of the requestor */
  subject_city?: string | null;
  /** Street of the requestor */
  subject_street?: string | null;
  /** House number of the requestor */
  subject_house_number?: string | null;
  /** Decision on the objection */
  decision?: ObjectionDecision | null;
  /** Motivation for the decision */
  decision_motivation?: string | null;
  /**
   * Status
   * The status of the objection
   */
  status: ObjectionStatus;
  /** Objection description */
  description: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the objection
   * @format uuid
   */
  id: string;
  /** Organization ID */
  organization_id: string;
  /** Municipality code */
  gemeentecode: string;
  /** Unique identifier of the objection */
  objection_number: string;
  /** Inbook variables */
  inboekvariabelen: InboekvariabelenOutput;
  /** List of grievances */
  grieven: Grief[];
  /** Established value in euros */
  established_value?: number | null;
  /** Claimed value in euros by the objector */
  claimed_value?: number | null;
  /** Corrected value after review */
  corrected_value?: number | null;
  /** List of letter advice texts */
  letter_advice_texts?: TempLetterText[] | null;
}

/** ObjectionStatus */
export enum ObjectionStatus {
  RECEIVED = "RECEIVED",
  UNDER_REVIEW = "UNDER_REVIEW",
  ADDITIONAL_INFORMATION_REQUESTED = "ADDITIONAL_INFORMATION_REQUESTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REVISION_REQUESTED = "REVISION_REQUESTED",
  RESOLVED = "RESOLVED",
  CANCELLED = "CANCELLED",
}

/** ObjectionUpdate */
export interface ObjectionUpdate {
  /** Decision on the objection */
  decision?: ObjectionDecision | null;
  /** Motivation for the decision */
  decision_motivation?: string | null;
  /**
   * Status
   * The status of the objection
   */
  status: ObjectionStatus;
  /** Objection description */
  description: string;
  /** Municipality code */
  gemeentecode: string;
  /** Unique identifier of the objection */
  objection_number: string;
  /** Inbook variables */
  inboekvariabelen: InboekvariabelenInput;
  /** List of grievances */
  grieven: Grief[];
  /** Established value in euros */
  established_value?: number | null;
  /** Claimed value in euros by the objector */
  claimed_value?: number | null;
  /** Corrected value after review */
  corrected_value?: number | null;
  /** Create letter for the objection */
  createLetter?: boolean | null;
  /** List of letter advice texts */
  letter_advice_texts?: TempLetterText[] | null;
}

/** StatsOut */
export interface StatsOut {
  /** Objections in progress */
  activeObjections: number;
  /** New objections */
  newObjections: number;
  /** Revisions in the last month */
  lastMonthRevisions: number;
  /** Unique identifier of the requestor */
  id: string;
}

/** TaxtatieType */
export enum TaxtatieType {
  BUREAU_TAXATIE = "BUREAU_TAXATIE",
  TER_PLAATSE = "TER_PLAATSE",
  INPANDIG = "INPANDIG",
}

/** TempLetterText */
export interface TempLetterText {
  /** Citaat */
  citaat: string;
  /** Decision */
  decision: string;
  /** Description */
  description: string;
  /** Valueinfluence */
  valueInfluence: boolean;
  /** Ready */
  ready?: boolean | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** VergelekenWoning */
export interface VergelekenWoning {
  /** Straat */
  straat: string;
  /** Huisnummer */
  huisnummer: string;
  /** Woonplaats */
  woonplaats: string;
  /** Bouwjaar */
  bouwjaar: string;
  /** Eenheid */
  eenheid: string;
  /** Oppervlakte */
  oppervlakte: string;
  /** Verkoopdatum */
  verkoopdatum: string;
  /** Verkoopprijs */
  verkoopPrijs: number;
  /** Kwaliteit */
  kwaliteit: number;
  /** Onderhoud */
  onderhoud: number;
  /** Ligging */
  ligging: number;
}

/** VergoedingOnderdelen */
export interface VergoedingOnderdelen {
  /** Indienenbezwaarschrift */
  indienenBezwaarschrift: boolean;
  /** Horen */
  horen: boolean;
  /** Taxatievverslag */
  taxatievVerslag: boolean;
  taxatieType?: TaxtatieType | null;
}
