import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ListProps,
  NumberField,
  TextField,
  useTranslate
} from 'react-admin'
import { ListActionsToolbar } from '../../components'
import { ObjectionOut } from '../../types/apiRequests'
import { municipalityCodeToName } from '../../utils/municipalityCodes'
import BulkImportButton from './bulk/BulkImportButton'
import StatusIcon from './StatusIcon'

const ObjectionsList: FC<ListProps> = (props) => {
  const translate = useTranslate()

  return (
    <StyledList
      {...props}
      exporter={false}
      actions={
        <ListActionsToolbar i18n="resources.objections.list">
          <BulkImportButton />
          <CreateButton variant="contained" label="resources.objections.create" />
        </ListActionsToolbar>
      }
      sort={{ field: 'created', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <EditButton label=" "/>
        <NumberField
          source="objection_number"
          label="data.objection_number"
          emptyText="-"
        />
        <TextField
          source="object_woz_number"
          label="data.object_woz_number"
          emptyText="-"
        />
        <FunctionField
          source="gemeentecode"
          label="data.gemeente"
          render={(record: ObjectionOut) => municipalityCodeToName(record?.gemeentecode)}
          sortable={false}
        />
        <DateField
          source="created"
          label="data.created"
        />
        <FunctionField
          source="status"
          label="data.status"
          render={(record: ObjectionOut) => (
            <StatusIcon
              status={record.status}
              created={record.created}
            />
          )}
        />
      </Datagrid>
    </StyledList>
  )
}

const PREFIX = 'xxllncToDosList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default ObjectionsList
